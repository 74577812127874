interface ErrorDetail {
	field: string,
	message: string,
	code: string
}

interface ErrorTemplateProps {
	error: string,
	details?: ErrorDetail[]
}

export const errorTemplate = ( { error, details }: ErrorTemplateProps ) => `
<!DOCTYPE html>
<html>
<head>
   <title>Error - CardConnect Application</title>
   <style>
      body {
         font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         height: 100vh;
         margin: 0;
         background-color: #f5f5f5;
         color: #333;
      }
      .logo {
         width: 200px;
         margin-bottom: 30px;
      }
      .error-icon {
         width: 60px;
         height: 60px;
         margin-bottom: 20px;
         color: #dc3545;
      }
      .error-icon svg {
         width: 100%;
         height: 100%;
      }
      .message {
         font-size: 24px;
         color: #dc3545;
         margin-bottom: 15px;
         text-align: center;
      }
      .error-details {
         font-size: 16px;
         color: #666;
         max-width: 500px;
         text-align: left;
         margin-bottom: 25px;
         padding: 20px;
         background: #fff;
         border-radius: 8px;
         box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }
      .error-list {
         margin-top: 15px;
         padding-left: 20px;
      }
      .error-item {
         margin-bottom: 10px;
         color: #dc3545;
      }
      .button-container {
         display: flex;
         gap: 10px;
         margin-bottom: 15px;
      }
      .button {
         padding: 10px 20px;
         border-radius: 5px;
         font-size: 16px;
         cursor: pointer;
         transition: background-color 0.2s;
         border: none;
      }
      .back-button {
         background-color: #dc3545;
         color: white;
      }
      .back-button:hover {
         background-color: #c82333;
      }
      .contact-button {
         background-color: #0070f3;
         color: white;
         text-decoration: none;
      }
      .contact-button:hover {
         background-color: #0051cc;
      }
      .copy-button {
         background-color: #6c757d;
         color: white;
      }
      .copy-button:hover {
         background-color: #5a6268;
      }
      #copyFeedback {
         display: none;
         text-align: center;
         color: #28a745;
         margin-top: 10px;
         font-size: 14px;
         position: fixed;
         bottom: 20px;
         left: 50%;
         transform: translateX(-50%);
         background: rgba(40, 167, 69, 0.9);
         color: white;
         padding: 8px 16px;
         border-radius: 4px;
      }
   </style>
   <script>
      function copyError() {
         const errorText = document.querySelector('.error-details').innerText;
         navigator.clipboard.writeText(errorText).then(() => {
            const feedback = document.getElementById('copyFeedback');
            feedback.style.display = 'block';
            setTimeout(() => {
               feedback.style.display = 'none';
            }, 2000);
         });
      }
   </script>
</head>
<body>
   <img src="/images/invoiss-logoblack.png" alt="Invoiss Logo" class="logo" />
   <div class="error-icon">
      <svg viewBox="0 0 24 24" fill="currentColor">
         <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
      </svg>
   </div>
   <div class="message">Application Error</div>
   <div class="error-details">
      ${error}
      ${details ? `
         <div class="error-list">
            ${details.map( ( detail ) => `
               <div class="error-item">
                  <strong>${detail.field}:</strong> ${detail.message}
               </div>
            ` ).join( '' )}
         </div>
      ` : ''}
   </div>
   <div class="button-container">
      <a href="https://invoiss.com/contact" target="_blank" class="button contact-button">Contact Support</a>
      <button onclick="copyError()" class="button copy-button">Copy Error</button>
   </div>
   <button class="button back-button" onclick="window.close()">Close Window</button>
   <div id="copyFeedback">Error details copied to clipboard!</div>
</body>
</html>
`;
