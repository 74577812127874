import CustomCheckBox from '@/components/customCheckBox';
import { CollapseWithGrid } from '@/components/customCollapse';
import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import { Divider, Grid, MenuItem, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import AddressFields from './addressFields';
import { Merchant } from './types';

const stateCodes = [
	'AA',
	'AB',
	'AE',
	'AK',
	'AL',
	'AP',
	'AR',
	'AS',
	'AZ',
	'BC',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'FM',
	'GA',
	'GU',
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MB',
	'MD',
	'ME',
	'MH',
	'MI',
	'MN',
	'MO',
	'MP',
	'MS',
	'MT',
	'NB',
	'NC',
	'ND',
	'NE',
	'NF',
	'NH',
	'NJ',
	'NL',
	'NM',
	'NS',
	'NT',
	'NU',
	'NV',
	'NY',
	'OH',
	'OK',
	'ON',
	'OR',
	'PA',
	'PE',
	'PR',
	'PW',
	'QC',
	'RI',
	'SC',
	'SD',
	'SK',
	'TN',
	'TX',
	'UT',
	'VA',
	'VI',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY',
	'YT',
];

export const demographicsInfoValidationSchema = yup.object().shape( {
	demographic: yup.object().shape( {
		merchantTimeZone: yup
			.string()
			.required( 'Enter a time zone' )
			.max( 64, 'Time zone is too long' ),
		websiteAddress: yup
			.string()
			.url( 'Please enter a valid URL starting with https://' )
			.required( 'Enter a website address' )
			.max( 64, 'Website address is too long' ),
		
		businessAddress: yup.object().shape( {
			address1: yup
				.string()
				.required( 'Enter an address' )
				.max( 64, 'Address is too long' ),
			city: yup
				.string()
				.required( 'Enter a city' )
				.max( 64, 'City is too long' ),
			stateCd: yup
				.string()
				.required( 'Enter a state' )
				.oneOf( stateCodes, 'State is not valid' )
				.max( 2, 'State is too long' ),
			zip: yup
				.string()
				.required( 'Enter a zip code' )
				.max( 64, 'Zip code is too long' ),
			countryCd: yup
				.string()
				.required( 'Enter a country (2-Code like US)' )
				.max( 2, 'Country is too long' ),
		} ),
		mailingAddress: yup.object().shape( {
			address1: yup
				.string()
				.required( 'Enter an address' )
				.max( 64, 'Address is too long' ),
			city: yup
				.string()
				.required( 'Enter a city' )
				.max( 64, 'City is too long' ),
			stateCd: yup
				.string()
				.required( 'Enter a state' )
				.oneOf( stateCodes, 'State is not valid' )
				.max( 2, 'State is too long' ),
			zip: yup
				.string()
				.required( 'Enter a zip code' )
				.max( 64, 'Zip code is too long' ),
			countryCd: yup
				.string()
				.required( 'Enter a country (2-Code like US)' )
				.max( 2, 'Country is too long (needs a 2-Code like US)' ),
		} ).when( 'sameAsBusinessAddress', {
			is  : true, // when 'sameAsBusinessAddress' is true
			then: yup.object().shape( {} ), // we ignore this field and apply an empty schema
		} ),
		sameAsBusinessAddress: yup.boolean(),
	} ),
} );

export default function DemographicsInfo() {
	const { values, setFieldValue } = useFormikContext<Merchant>();
	
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography gutterBottom variant='h4' fontWeight='500'>
					Demographic Information
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<FormSelect
					fullWidth
					name='demographic.merchantTimeZone'
					label='Merchant Time Zone'
					defaultValue='ET'>
					<MenuItem value='ET'>Eastern Time</MenuItem>
					<MenuItem value='CT'>Central Time</MenuItem>
					<MenuItem value='PT'>Pacific Time</MenuItem>
				</FormSelect>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='demographic.websiteAddress'
					label='Website Address'
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider sx={{ my: 1 }}/>
			</Grid>
			<Grid item xs={12}>
				<Typography gutterBottom variant='h4' fontWeight='500'>
					Business Address
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<AddressFields path='demographic.businessAddress'/>
			</Grid>
			<Grid item xs={12}>
				<Divider sx={{ my: 1 }}/>
			</Grid>
			<Grid item xs={12}>
				<FormFormControlLabel
					name='demographic.sameAsBusinessAddress'
					control={<CustomCheckBox/>}
					sx={{ width: '100%' }}
					label='My mailing address is the same as my business address'
					onChange={( e, checked ) => {
						if ( checked ) {
							setFieldValue( 'demographic.mailingAddress', values.demographic.businessAddress );
						} else {
							setFieldValue( 'demographic.mailingAddress', {
								address1 : '',
								city     : '',
								stateCd  : '',
								zip      : '',
								countryCd: 'US',
							} );
						}
					}}
				/>
			</Grid>
			<CollapseWithGrid in={!values.demographic.sameAsBusinessAddress}>
				<Grid item xs={12}>
					<Typography gutterBottom variant='h4' fontWeight='500'>
						Mailing Address
					</Typography>
					<AddressFields path='demographic.mailingAddress'/>
				</Grid>
			</CollapseWithGrid>
		</Grid>
	);
}
